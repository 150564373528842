<template>
  <button type="submit" :class="buttonClasses" :disabled="disabled || btnDisable" @click="handleClick">
    <svg v-if="disabled" class="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
      viewBox="0 0 24 24" :class="spinnerColor">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
      </path>
    </svg>
    <slot name="icon" v-if="icon && !disabled">
      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
    </slot>
    <div v-if="content" v-html="content"></div>
    <slot v-else></slot>
  </button>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  disabled: { type: Boolean, default: false },
  btnDisable: { type: Boolean, default: false },
  variant: { type: String, default: 'primary', validator: value => ['primary', 'secondary', 'link'].includes(value) },
  size: { type: String, default: 'base', validator: value => ['sm', 'base', 'lg'].includes(value) },
  icon: { type: Boolean, default: false },
  iconPosition: { type: String, default: 'left', validator: value => ['left', 'right'].includes(value) },
  spinnerColor: { type: String, default: 'text-white' },
  content: { type: String, default: '' },
  hoverColor: { type: String, default: '' }
});

const btnClass = ref('flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out');

const sizeClasses = {
  sm: 'text-xs py-1 px-2',
  base: 'text-sm py-2 px-4',
  lg: 'text-lg py-3 px-5'
};

const variantClasses = {
  primary: 'bg-blue-600 text-white',
  secondary: 'bg-gray-600 text-white',
  link: 'text-blue-600 font-medium'
};

const sizeClass = computed(() => sizeClasses[props.size]);
const variantClass = computed(() => variantClasses[props.variant]);

const buttonClasses = computed(() => [
  btnClass.value,
  sizeClass.value,
  variantClass.value,
  { 'cursor-not-allowed opacity-75': props.disabled || props.btnDisable },
  { 'flex-row-reverse': props.iconPosition === 'right' },
  props.hoverColor || 'hover:bg-blue-700'
]);

const handleClick = (event) => {
  if (props.disabled || props.btnDisable) {
    event.preventDefault();
  }
};
</script>

<style scoped>
button {
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;
}

button.lg {
  gap: 0.5rem;
}
</style>
