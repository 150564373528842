<template>
  <div>
    <div>
      <nav class="hidden sm:flex items-center text-sm leading-5 font-medium">
        <router-link to="/service/server"
          class="text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-gray-300 transition duration-150 ease-in-out">
          {{ $t('server.breadcrumbs.services') }}
        </router-link>
        <svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd" />
        </svg>
        <router-link to="/service/server"
          class="text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-gray-300 transition duration-150 ease-in-out">
          {{ $t('server.breadcrumbs.server') }}
        </router-link>
        <template v-if="server.uuid">
          <svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd" />
          </svg>
          <router-link :to="`/service/server/${server.uuid}/overview`"
            class="text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-gray-300 transition duration-150 ease-in-out">
            {{ server.name }}
          </router-link>
          <svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd" />
          </svg>
          <router-link :to="$route.path"
            class="text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-gray-300 transition duration-150 ease-in-out">
            {{ $t('server.breadcrumbs.' + $route.name) }}
          </router-link>
        </template>
      </nav>
    </div>

    <div class="mt-2 md:flex md:items-center md:justify-between">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-5 text-gray-900 dark:text-gray-100 sm:text-3xl sm:leading-9 sm:truncate flex items-baseline group">
          <svg class="mr-2 h-5 w-5 text-green-400" fill="currentColor" viewBox="0 0 8 8"
            v-if="server.power_state === ServerStatusEnum.POWER_STARTED">
            <circle cx="4" cy="4" r="3" />
          </svg>
          <svg class="mr-2 h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 8 8"
            v-else-if="server.power_state === ServerStatusEnum.POWER_STOPPED">
            <circle cx="4" cy="4" r="3" />
          </svg>
          <svg class="mr-2 h-4 w-4 text-gray-400" fill="currentColor" viewBox="0 0 8 8" v-else>
            <circle cx="4" cy="4" r="3" />
          </svg>
          <server-name :server="server" @updated="updateServerName" />
        </h2>
      </div>
      <div class="mt-4 flex flex-wrap items-center justify-start md:justify-between">
        <BackToOverviewButton
          class="mr-2 mb-2 md:mb-0 inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none"
          to="/service/server" :short="true" />

        <Button :btnDisable="isLocked || server.expired_at !== null"
          v-if="server.power_state !== ServerStatusEnum.POWER_STARTED && !server?.suspended"
          @click="submitAction('start')" :disabled="isSubmit" class="mr-2 mb-2 md:mb-0">
          {{ $t('server.power_on') }}
        </Button>

        <Button :btnDisable="isLocked || server.expired_at !== null"
          v-if="server.power_state === ServerStatusEnum.POWER_STARTED && !server?.suspended" @click="submitAction('stop')"
          :disabled="isSubmit" class="mr-2 mb-2 md:mb-0">
          {{ $t('server.power_off') }}
        </Button>

        <server-action-button v-if="!server?.suspended" :server="server" @successUpgrade="successUpgrade"
          @refreshServer="refreshServer" class="mb-2 md:mb-0 inline-flex" />
        <extend-server-runtime-button :server="server" @refreshServer="refreshServer" v-if="!server?.suspended"
          class="mr-2 mb-2 md:mb-0 inline-flex" />
        <template v-if="server?.suspended">
          <RouterLink to="/support/abuse" as="button" type="button"
            class="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none">
            {{ $t('server.go_to_abuse_cases') }}
          </RouterLink>
        </template>
      </div>
    </div>

    <div class="mt-3 flex items-center space-x-3">
      <span v-if="server.power_state === ServerStatusEnum.POWER_STOPPED && !server.suspended"
        class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
        {{ $t('server.power_stopped') }}
      </span>
      <span v-if="server.power_state === ServerStatusEnum.POWER_STARTED && !server.suspended"
        class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300">
        {{ $t('server.power_started') }}
      </span>
      <span v-if="server.suspended"
        class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300">
        {{ $t('server.status_suspended') }}
      </span>
      <span v-if="server.task_lock || server.status !== 'installed'"
        class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
        <svg class="animate-spin -ml-1 mr-1.5 h-3 w-3 text-blue-800" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
          </path>
        </svg>
        {{ $t('server.status_processing') }}
      </span>
      <div v-if="server && server.auto_extend === true"
        class="inline-flex items-center space-x-1.5 px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300">
        <svg class="h-4 w-4 text-green-700 dark:text-green-300" fill="none" stroke="currentColor" stroke-width="2"
          viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99">
          </path>
        </svg>
        <span>{{ $t('server.auto_extend') }}</span>
      </div>
      <router-link v-if="server && server.auto_extend === false" :to="`/service/server/${server.uuid}/settings`"
        class="inline-flex items-center space-x-1.5 px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300">
        <svg class="h-4 w-4 text-yellow-700 dark:text-yellow-200" fill="currentColor" viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path clip-rule="evenodd" fill-rule="evenodd"
            d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z">
          </path>
        </svg>
        <span>{{ $t('server.auto_extend_disabled') }}</span>
      </router-link>
    </div>

    <transition>
      <div class="rounded-md bg-green-50 dark:bg-green-900 p-4 shadow mt-6" v-if="isServerNameUpdated">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-green-400 dark:text-green-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor">
              <path fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 font-normal text-green-800 dark:text-green-300">
              {{ $t('server.name_updated_success') }}
            </p>
          </div>
        </div>
      </div>
    </transition>

    <transition>
      <div class="rounded-md bg-green-50 dark:bg-green-900 p-4 shadow mt-6" v-if="isServerExtended">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-green-400 dark:text-green-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor">
              <path fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 font-normal text-green-800 dark:text-green-300">
              {{ $t('server.extended_success') }}
            </p>
          </div>
        </div>
      </div>
    </transition>

    <transition>
      <div class="rounded-md bg-red-50 p-4 shadow mt-6" v-if="server?.suspended">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 font-normal text-red-800">
              {{ $t('server.suspended_warning') }}
            </p>
          </div>
        </div>
      </div>
    </transition>

    <transition>
      <div class="rounded-md bg-green-50 dark:bg-green-900 p-4 shadow mt-6" v-if="isReInstalled">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-green-400 dark:text-green-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor">
              <path fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 font-normal text-green-800 dark:text-green-300">
              {{ $t('server.reinstall_success') }}
            </p>
          </div>
        </div>
      </div>
    </transition>

    <transition>
      <div class="rounded-md bg-green-50 dark:bg-green-900 p-4 shadow mt-6" v-if="isUpgradeServerSuccess">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-green-400 dark:text-green-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor">
              <path fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 font-normal text-green-800 dark:text-green-300">
              {{ $t('server.successfully_updated') }}
            </p>
          </div>
        </div>
      </div>
    </transition>

    <transition>
      <div class="rounded-md bg-green-50 dark:bg-green-900 p-4 shadow mt-6" v-if="isServerUpgradeStatus">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-green-400 dark:text-green-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor">
              <path fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 font-normal text-green-800 dark:text-green-300">
              {{ $t('upgrade.modal.success') }}
            </p>
          </div>
        </div>
      </div>
    </transition>

    <transition v-if="isVncProcessing">
      <div class="rounded-md bg-blue-50 p-4 shadow mt-6">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-blue-700">
              {{ $t('server.vnc_processing') }}
            </p>
            <p class="mt-3 text-sm md:mt-0 md:ml-6 h-5">
              <button @click="refresh"
                class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600 dark:text-blue-300">
                <template v-if="!isLoading">
                  {{ $t('global.refresh') }}
                  <span aria-hidden="true">&rarr;</span>
                </template>
                <template v-else>
                  <svg class="animate-spin h-5 w-5 text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                    </path>
                  </svg>
                </template>
              </button>
            </p>
          </div>
        </div>
      </div>
    </transition>

    <!-- third-level navigation for mobile -->
    <div class="block md:hidden mt-4">
      <select v-model="selectedPage" @change="navigateToPage"
        class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
        <option value="overview">{{ $t('server.breadcrumbs.Overview') }}</option>
        <option value="disks">{{ $t('server.breadcrumbs.Disks') }}</option>
        <option value="network">{{ $t('server.breadcrumbs.Network') }}</option>
        <option value="backup" v-if="server.backup_enabled">{{ $t('server.breadcrumbs.Backup') }}</option>
        <option value="snapshots" v-if="server.snapshot_enabled">{{ $t('server.breadcrumbs.Snapshots') }}</option>
        <option value="upgrades">{{ $t('server.breadcrumbs.Upgrades') }}</option>
        <option value="vnc">{{ $t('server.breadcrumbs.VNC') }}</option>
        <option value="statistics">{{ $t('server.breadcrumbs.Statistics') }}</option>
        <option value="settings">{{ $t('server.breadcrumbs.Settings') }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/v2/Button.vue'
import ServerActionButton from '@/components/service/buttons/ServerActionButton'
import ExtendServerRuntimeButton from '@/components/service/buttons/ExtendServerRuntimeButton'
import ServerName from '@/components/service/server/ServerName'
import { ServerStatusEnum } from '@/enums'
import BackToOverviewButton from '@/components/common/buttons/BackToOverviewButton'

export default {
  name: 'ServerHeader',

  props: {
    server: {
      type: Object,
      required: true
    }
  },

  components: {
    Button,
    ExtendServerRuntimeButton,
    ServerActionButton,
    ServerName,
    BackToOverviewButton
  },

  computed: {
    ...mapGetters([
      'serverErrorMessages',
      'isReInstalled',
      'isLocked',
      'isExpired',
      'isServerUpgrade'
    ]),

    isVncProcessing() {
      return this.server.status !== this.ServerStatusEnum.INSTALL_STATUS_DONE && this.$route.path.indexOf('vnc') > -1
    }
  },

  data() {
    return {
      isLoading: false,
      isServerUpgradeStatus: false,
      isUpgradeServerSuccess: false,
      isSubmit: false,
      isServerNameUpdated: false,
      isServerExtended: false,
      ServerStatusEnum: ServerStatusEnum,
      session: [],
      selectedPage: this.getCurrentPage(),
    }
  },

  mounted() {
    if (this.isReInstalled) {
      setTimeout(() => {
        this.$store.dispatch('setReInstallEmpty')
      }, 6000)
    }
  },

  methods: {
    navigateToPage() {
      this.$router.push(`/service/server/${this.server.uuid}/${this.selectedPage}`);
    },

    getCurrentPage() {
      const pathParts = this.$route.path.split('/');
      return pathParts[pathParts.length - 1] || 'overview';
    },

    successUpgrade() {
      this.isUpgradeServerSuccess = true

      setTimeout(() => {
        this.isUpgradeServerSuccess = false
      }, 3000)
    },

    submitAction(action) {
      this.isSubmit = true

      this.$store.dispatch('serverAction', {
        serverId: this.server.uuid,
        action: action,
      }).then(() => {
        this.isSubmit = false
      })
    },

    refreshServer() {
      this.isServerExtended = true
      this.$emit('refreshServer')

      setTimeout(() => {
        this.isServerExtended = false
      }, 3000)
    },

    refresh() {
      this.isLoading = true

      this.$store.dispatch('getServer', this.$route.params.id).then(() => {
        this.isLoading = false
      })
    },

    updateServerName() {
      this.isServerNameUpdated = true

      setTimeout(() => {
        this.isServerNameUpdated = false
      }, 3000)
    }
  },
  watch: {
    serverErrorMessages(formErrors) {
      if (formErrors && formErrors.error) {
        this.isSubmit = false

        this.$toast.error(this.$t(formErrors.error.name))
      }
    },

    isServerUpgrade(status) {
      if (status) {
        this.isServerUpgradeStatus = true

        setTimeout(() => {
          this.isServerUpgradeStatus = false
        }, 3000)
      }
    }
  },
};
</script>
