<template>
  <div class="mr-2 relative inline-block text-left" v-click-away="onClickAway">
    <div>
      <button type="button" @click="showDropdown = !showDropdown"
        class="inline-flex justify-center w-full rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-700 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 focus:outline-none"
        id="menu-button" aria-expanded="true" aria-haspopup="true">
        {{ $t('server.buttons.actions') }}
        <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
          aria-hidden="true">
          <path fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd" />
        </svg>
      </button>
    </div>

    <div v-show="showDropdown"
      class="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-500 focus:outline-none"
      role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
      <div class="py-1" role="none">
        <a href="#" @click.prevent="openExtendModal" :class="isLocked ? 'cursor-not-allowed' : ''"
          class="w-full text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-900 dark:text-gray-100"
          role="menuitem" tabindex="-1">
          <svg
            class="-ml-0.5 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:group-hover:text-gray-300 dark:group-focus:text-gray-400"
            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"></path>
          </svg>
          {{ $t('server.buttons.extend_server') }}
        </a>
      </div>
      <div class="py-1" role="none">
        <a href="#" @click.prevent="openAdditionalModal" :class="isLocked || isExpired ? 'cursor-not-allowed' : ''"
          class="w-full text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-900 dark:text-gray-100"
          role="menuitem" tabindex="-1">
          <svg
            class="-ml-0.5 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:group-hover:text-gray-300 dark:group-focus:text-gray-400"
            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z">
            </path>
          </svg>
          {{ $t('server.buttons.create_additional_disk') }}
        </a>
        <a href="#" @click.prevent="openCreatePortModal" :class="isLocked || isExpired ? 'cursor-not-allowed' : ''"
          class="w-full text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-900 dark:text-gray-100"
          role="menuitem" tabindex="-1">
          <svg
            class="-ml-0.5 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:group-hover:text-gray-300 dark:group-focus:text-gray-400"
            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
              clip-rule="evenodd"></path>
          </svg>
          {{ $t('server.buttons.create_port') }}
        </a>
      </div>
      <div class="py-1" role="none">
        <a href="#" v-if="hasPrimaryIp && hasIpType(4)" @click.prevent="openIpChangeModal"
          :class="isLocked || isExpired ? 'cursor-not-allowed' : ''"
          class="w-full text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-900 dark:text-gray-100"
          role="menuitem" tabindex="-1">
          <svg
            class="-ml-0.5 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:group-hover:text-gray-300 dark:group-focus:text-gray-400"
            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path clip-rule="evenodd" fill-rule="evenodd"
              d="M13.2 2.24a.75.75 0 00.04 1.06l2.1 1.95H6.75a.75.75 0 000 1.5h8.59l-2.1 1.95a.75.75 0 101.02 1.1l3.5-3.25a.75.75 0 000-1.1l-3.5-3.25a.75.75 0 00-1.06.04zm-6.4 8a.75.75 0 00-1.06-.04l-3.5 3.25a.75.75 0 000 1.1l3.5 3.25a.75.75 0 101.02-1.1l-2.1-1.95h8.59a.75.75 0 000-1.5H4.66l2.1-1.95a.75.75 0 00.04-1.06z">
            </path>
          </svg>
          {{ $t('server.buttons.change_ip') }}
        </a>
        <a href="#" @click.prevent="openUpgradeServerModal" :class="isLocked || isExpired ? 'cursor-not-allowed' : ''"
          class="w-full text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-900 dark:text-gray-100"
          role="menuitem" tabindex="-1">
          <svg
            class="-ml-0.5 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:group-hover:text-gray-300 dark:group-focus:text-gray-400"
            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z"
              clip-rule="evenodd"></path>
          </svg>
          {{ $t('server.buttons.upgrade_server') }}
        </a>
      </div>
      <div class="py-1" role="none">
        <router-link :to="isLocked || isExpired ? '' : `/service/server/${server.uuid}/reinstall`"
          :class="isLocked ? 'cursor-not-allowed' : ''"
          class="w-full text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-900 dark:text-gray-100"
          role="menuitem" tabindex="-1">
          <svg
            class="-ml-0.5 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:group-hover:text-gray-300 dark:group-focus:text-gray-400"
            fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
          </svg>
          {{ $t('server.buttons.reinstall_server') }}
        </router-link>
      </div>
    </div>
  </div>

  <extend-server-runtime-modal :isOpen="showExtendServerRuntimeModal" :server="server"
    @close="showExtendServerRuntimeModal = false" @extendSuccess="refreshServer" />

  <change-ip-modal v-if="isChangeIpModal" :server="server" @success="closeIpChangeModal" @close="closeIpChangeModal" />

  <create-additional-disk-modal :isOpen="isCreateAdditionalModal" :server="server" @success="closeAdditionalModal"
    @close="closeAdditionalModal" />

  <create-port-modal :isOpen="isCreatePortModal" :server="server" @close="closePortModal" />

  <upgrade-flavor-modal v-if="isUpgradeServerModal" :server="server" @close="closeUpgradeServerModal"
    @success="successUpgrade" />
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue3-click-away'
import ExtendServerRuntimeModal from '@/components/service/modals/ExtendServerRuntimeModal'
import ChangeIpModal from '@/components/service/modals/ChangeIpModal'
import CreateAdditionalDiskModal from '@/components/service/modals/CreateAdditionalDiskModal'
import CreatePortModal from '@/components/service/modals/CreatePortModal'
import UpgradeFlavorModal from '@/components/service/modals/UpgradeFlavorModal'
import { ServerPortTypeEnum } from '@/enums'

export default {
  name: 'ServerActionButton',
  components: { CreateAdditionalDiskModal, ChangeIpModal, ExtendServerRuntimeModal, UpgradeFlavorModal, CreatePortModal },
  computed: {
    ...mapGetters(['isLocked', 'isExpired'])
  },
  props: {
    server: {
      required: true,
      type: Object,
    },
  },
  mixins: [clickaway],
  data() {
    return {
      showDropdown: false,
      showExtendServerRuntimeModal: false,
      isChangeIpModal: false,
      isCreateAdditionalModal: false,
      isCreatePortModal: false,
      isUpgradeServerModal: false,
      isUpgradeServerSuccess: false
    }
  },
  methods: {
    hasIpType(type) {
      if (!this.server || !this.server.ports) {
        return false;
      }

      return this.server.ports.some(port =>
        port.type === ServerPortTypeEnum.TYPE_PUB_PRIMARY &&
        port.ip_allocations.some(ip => ip.ip_family === type)
      );
    },
    hasPrimaryIp() {
      if (!this.server || !this.server.ports) {
        return false;
      }

      return this.server?.ports?.some(port => port.type === ServerPortTypeEnum.TYPE_PUB_PRIMARY) || false;
    },
    onClickAway() {
      this.showDropdown = false
    },
    openExtendModal() {
      if (this.isLocked) {
        return
      }

      this.showDropdown = false
      this.showExtendServerRuntimeModal = true
    },
    openIpChangeModal() {
      if (this.isLocked || this.isExpired) {
        return
      }

      this.showDropdown = false
      this.isChangeIpModal = true
    },
    closeIpChangeModal() {
      this.isChangeIpModal = false
    },
    openAdditionalModal() {
      if (this.isLocked || this.isExpired) {
        return
      }

      this.showDropdown = false
      this.isCreateAdditionalModal = true
    },
    closeAdditionalModal() {
      this.isCreateAdditionalModal = false
    },
    openUpgradeServerModal() {
      if (this.isLocked || this.isExpired) {
        return
      }

      this.showDropdown = false
      this.isUpgradeServerModal = true
    },
    closeUpgradeServerModal() {
      this.isUpgradeServerModal = false
    },
    successUpgrade() {
      this.$emit('successUpgrade')
      this.closeUpgradeServerModal()
    },
    openCreatePortModal() {
      if (this.isLocked || this.isExpired) {
        return
      }

      this.showDropdown = false
      this.isCreatePortModal = true
    },
    closePortModal() {
      this.isCreatePortModal = false
    },
    refreshServer() {
      this.showExtendServerRuntimeModal = false

      this.$emit('refreshServer')
    }
  }
}
</script>
