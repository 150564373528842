<template>
  <transition>
    <Modal :open="isOpen" @close="close">
      <template #icon>
        <svg class="h-6 w-6 text-blue-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
        </svg>
      </template>
      <template #title>
        <div class="text-center md:text-left mt-4 md:mt-0">
          {{ $t('server.create_port_modal.title') }}
        </div>
      </template>
      <template #subtitle>
        {{ $t('server.create_port_modal.description') }}
      </template>
      <template #default>
        <create-port-modal-loader v-if="isLoadingPools" />

        <div v-else-if="availablePools.length" class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="col-span-6">
            <label for="privateNetwork" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
              {{ $t('server.create_port_modal.private_network') }}
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <select id="privateNetwork" v-model="form.private_network"
                class="appearance-none block w-full px-3 py-2 dark:bg-gray-700 border border-gray-300 dark:text-gray-100 dark:border-gray-600 rounded-md placeholder-gray-400 focus:invalid-red-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                :class="{ 'border-red-500': errors.private_network, 'focus:border-blue-300': !errors.private_network }">
                <option v-for="pool in availablePools" :key="pool.uuid" :value="pool.uuid">
                  {{ pool.alias }} - {{ pool.network }}/{{ pool.network_length }}
                </option>
              </select>
            </div>
            <div class="mt-1 text-xs text-red-500" v-if="errors.private_network">
              {{ errors.private_network[0] }}
            </div>
            <div v-else class="mt-1 text-xs text-gray-600 dark:text-gray-300">
              {{ $t('server.create_port_modal.private_network_description') }}
            </div>
          </div>

          <div class="col-span-6">
            <span class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
              {{ $t('server.create_port_modal.ip_allocation_method') }}
            </span>
            <div class="mt-2 space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              <div class="flex items-center">
                <input id="automatic-port" type="radio" v-model="form.allocation_method" value="automatical"
                  class="h-4 w-4 border-gray-300 text-blue-600 dark:text-blue-600 focus:ring-0 focus:ring-offset-0">
                <label for="automatic-port" class="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300">
                  {{ $t('server.create_port_modal.ip_allocation_method_dynamic') }}
                </label>
              </div>
              <div class="flex items-center">
                <input id="manual-port" type="radio" v-model="form.allocation_method" value="manual"
                  class="h-4 w-4 border-gray-300 text-blue-600 dark:text-blue-600 focus:ring-0 focus:ring-offset-0">
                <label for="manual-port" class="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300">
                  {{ $t('server.create_port_modal.ip_allocation_method_manual') }}
                </label>
              </div>
            </div>
            <div class="mt-1 text-xs text-gray-600 dark:text-gray-300">
              {{ form.allocation_method === 'automatical'
                ? $t('server.create_port_modal.ip_allocation_method_dynamic_description')
                : $t('server.create_port_modal.ip_allocation_method_manual_description') }}
            </div>
          </div>

          <div class="col-span-6" v-if="form.allocation_method === 'manual'">
            <label for="ipAddress" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
              {{ $t('server.create_port_modal.ip_address') }}
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input id="ipAddress" v-model="form.ip_address"
                class="appearance-none block w-full dark:bg-gray-700 dark:text-gray-100 dark:border-gray-600 px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                :class="{ 'border-red-500': errors.ip_address, 'focus:border-blue-300': !errors.ip_address }">
            </div>
            <div class="mt-1 text-xs text-red-500" v-if="errors.ip_address">
              {{ errors.ip_address[0] }}
            </div>
            <div v-else class="mt-1 text-xs text-gray-600 dark:text-gray-400">
              {{ $t('server.create_port_modal.ip_address_description') }}
            </div>
          </div>
        </div>

        <div v-else class="mt-6">
          <div class="rounded-md bg-yellow-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                  fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-yellow-800">
                  {{ $t('server.create_port_modal.missing_private_networks_title') }}
                </h3>
                <div class="mt-2 text-sm text-yellow-700">
                  {{ $t('server.create_port_modal.missing_private_networks_description') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button-spinner v-if="availablePools.length" :disabled="isLoading" :btnDisable="!form.private_network" @click="submit"
          class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">
          {{ $t('server.create_port_modal.create_btn') }}
        </button-spinner>
        <button @click.prevent="close" type="button"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-gray-700 px-3 py-2 text-sm font-medium text-gray-900 dark:text-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 sm:mt-0 sm:w-auto">
          {{ $t('global.cancel') }}
        </button>
      </template>
    </Modal>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/v2/Modal.vue'
import ButtonSpinner from '@/components/form/ButtonSpinner'
import CreatePortModalLoader from '@/components/loader/CreatePortModalLoader'

export default {
  name: 'CreatePort',

  components: {
    CreatePortModalLoader,
    Modal,
    ButtonSpinner
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    server: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters([
      'availablePools',
      'isCreatedPort',
      'createPortErrors'
    ])
  },

  data() {
    return {
      isLoading: false,
      isLoadingPools: false,
      errors: {},
      form: {
        private_network: '',
        allocation_method: 'automatical',
        ip_address: ''
      }
    }
  },

  mounted() {
    this.loadAvailablePools()
  },

  methods: {
    loadAvailablePools() {
      this.isLoadingPools = true
      this.$store.dispatch('getAvailablePools', this.server.uuid)
        .finally(() => {
          this.isLoadingPools = false
        })
    },

    close() {
      this.$emit('close')
    },

    submit() {
      this.isLoading = true
      const data = {
        serverId: this.server.uuid,
        form: this.form
      }

      this.$store.dispatch('createPort', data)
    }
  },

  watch: {
    isCreatedPort(status) {
      if (status) {
        this.$toast.success(this.$t('privateNetwork.portCreated'), {
          duration: 3000,
          onClose: () => {
            this.close()
          }
        })
      }
    },

    createPortErrors(formErrors) {
      if (formErrors) {
        const { errors, message } = formErrors
        this.isLoading = false
        this.errors = {
          ...this.errors,
          ...errors
        }

        this.$toast.error(message)
      }
    }
  }
}
</script>
