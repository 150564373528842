<template>
  <Button :btnDisable="isLocked" @click.prevent="showExtendServerRuntimeModal = true">
    {{ $t('server.buttons.extend_server') }}
  </button>
  <extend-server-runtime-modal :isOpen="showExtendServerRuntimeModal" @close="closeModal"
    @extendSuccess="handleExtendSuccess" :server="server" />
</template>

<script>
import { mapGetters } from 'vuex'
import ExtendServerRuntimeModal from '@/components/service/modals/ExtendServerRuntimeModal'
import Button from '@/components/v2/Button'

export default {
  name: 'ExtendServerRuntimeButton',

  components: { ExtendServerRuntimeModal, Button },

  computed: {
    ...mapGetters(['isLocked']),
  },

  props: {
    server: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      showExtendServerRuntimeModal: false,
    }
  },

  methods: {
    closeModal() {
      this.showExtendServerRuntimeModal = false
    },
    handleExtendSuccess() {
      this.$emit('refreshServer')
    }
  },

  destroyed() {
    this.$destroy()
  }
}
</script>
