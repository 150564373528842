<template>
  <transition>
    <Modal :open="isOpen" @close="close">
      <template #icon>
        <svg class="h-6 w-6 text-blue-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
        </svg>
      </template>
      <template #title>
        {{ $t('disk.create_modal.create_additional_disk') }}
      </template>

      <template #subtitle>
        {{ $t('disk.create_modal.create_additional_disk_text') }}
      </template>

      <template #default>
        <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="col-span-6 grid grid-cols-6 gap-x-4">
            <div class="col-span-6 sm:col-span-4">
              <label for="name" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                {{ $t('disk.create_modal.name') }}
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input id="name" v-model="diskName" type="text"
                  :class="{ 'border-red-500 focus:ring-red-500': errors.name, 'focus:outline-none focus:border-blue-500 focus:ring-blue-500': !errors.name }"
                  class="dark:bg-gray-700 dark:border-gray-600 dark:text-white shadow-sm block w-full sm:text-sm border-gray-300 rounded-md">
              </div>
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label for="size" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                {{ $t('disk.create_modal.size') }}
              </label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input id="size" type="text"
                  class="dark:bg-gray-700 dark:border-gray-600 dark:text-white shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="min. 1"
                  :class="{ 'border-red-500 focus:ring-red-500': errors.size, 'focus:outline-none focus:border-blue-500 focus:ring-blue-500': !errors.size }"
                  aria-describedby="size-unit" v-model="size" @keypress="isNumber($event)">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 dark:text-gray-400 sm:text-sm sm:leading-5" id="size-unit">
                    GB
                  </span>
                </div>
              </div>
            </div>
            <div class="col-span-6">
              <div class="mt-2 text-sm text-gray-500 dark:text-gray-100">
                {{ $t('disk.create_modal.name_label') }}
              </div>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="country" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
              {{ $t('disk.create_modal.auto_format') }}
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <select id="autoFormat" v-model="autoFormat"
                class="dark:bg-gray-700 dark:border-gray-600 dark:text-white appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                <option v-if="isLinux" value="ext4">ext4</option>
                <option v-if="isLinux" value="xfs">xfs</option>
                <option value="manual">{{ $t('disk.create_modal.format_manual') }}</option>
              </select>
            </div>
          </div>

          <div class="col-span-6">
            <template v-if="autoFormat !== 'manual'">
              <div class="text-sm text-gray-500 dark:text-gray-100" v-html="$t('disk.create_modal.auto_format_choosing')">
              </div>
              <div class="text-sm text-gray-500 dark:text-gray-100 mt-2">
                {{ $t('disk.create_modal.auto_format_choosing_not_restart') }}
              </div>
            </template>
            <template v-else>
              <div class="text-sm text-gray-500 dark:text-gray-100"
                v-html="$t('disk.create_modal.manual_format_choosing')"></div>
              <div class="text-sm text-red-500 mt-2">
                <div class="flex items-center space-x-1">
                  <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <p class="font-medium">
                    {{ $t('disk.create_modal.warning') }}
                  </p>
                </div>
                <div class="mt-1">{{ $t('disk.create_modal.manual_format_only') }}</div>
              </div>
            </template>
          </div>

          <div class="col-span-3">
            <ImmediatePrice :label="$t('disk.create_modal.immediate_payment')" :amount="immediate"
              :isLoading="isPriceLoading"
              :nextPayment="$t('disk.create_modal.next_payment') + ' ' + $filters.dateTime(server.extended_until, 'YYYY-MM-DD')" />
          </div>

          <div class="col-span-3">
            <ImmediatePrice :label="$t('disk.create_modal.server_price')"
              :amount="monthlyDiskPrice ? '+ ' + monthlyDiskPrice + ' € / ' + $t('disk.create_modal.month') : null"
              :isLoading="isPriceLoading" :nextPayment="$t('disk.create_modal.server_price_monthly')" />
          </div>

          <div class="col-span-6">
            <div class="text-xs text-gray-500 dark:text-gray-100" v-if="newMonthlyServerPrice"
              v-html="$t('disk.create_modal.by_clicking', { immediate: immediate + ' &euro;' ?? '-', monthlyDiskPrice: monthlyDiskPrice, newMonthlyServerPrice: newMonthlyServerPrice })">
            </div>
          </div>

          <div class="col-span-6 border border-red-200 p-4 bg-red-50 rounded" v-if="isEnoughBalance">
            <div class="text-sm text-red-500">
              <div class="flex items-center space-x-1">
                <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"></path>
                </svg>
                <p class="font-medium">{{ $t('disk.create_modal.insufficient_balance') }}</p>
              </div>
              <div class="mt-1" v-html="$t('disk.create_modal.no_money', { immediate: immediate })">
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button-spinner :disabled="isLoading" :btnDisable="isEnoughBalance" @click="submit"
          class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">
          {{ $t('disk.create_modal.create') }}
        </button-spinner>
        <button @click.prevent="close" type="button"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-gray-700 px-3 py-2 text-sm font-medium text-gray-900 dark:text-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 sm:mt-0 sm:w-auto">
          {{ $t('disk.create_modal.cancel') }}
        </button>
      </template>
    </modal>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/v2/Modal.vue'
import ImmediatePrice from '@/components/v2/ImmediatePrice.vue'
import ButtonSpinner from '@/components/form/ButtonSpinner'
import { debounce } from '@/helpers/debounce'
import Dinero from 'dinero.js'

export default {
  name: 'CreateAdditionalDiskModal',

  props: {
    server: {
      required: true,
      type: Object
    },
    isOpen: {
      required: true,
      type: Boolean
    }
  },

  components: {
    Modal,
    ButtonSpinner,
    ImmediatePrice
  },

  computed: {
    ...mapGetters([
      'diskPricing',
      'diskPricingError',
      'isDiskCreated',
      'diskCreateErrors'
    ]),
    ...mapGetters({ balance: 'getBalance' }),
    isEnoughBalance() {
      return this.balance.cents < this.diskPricing.immediate_payment
    },
    isLinux() {
      return this.server && this.server.image && this.server.image.os_family === 'linux';
    },
  },

  data() {
    return {
      isPriceLoading: false,
      isLoading: false,
      insufficientBalance: false,
      monthlyDiskPrice: null,
      immediate: null,
      newMonthlyServerPrice: null,
      size: '',
      diskName: '',
      autoFormat: 'ext4',
      serverInitialized: false,
      errors: {}
    }
  },

  methods: {
    initializeAutoFormat() {
      this.autoFormat = this.isLinux ? 'ext4' : 'manual';
    },

    close() {
      this.$emit('close')
      this.diskName = ''
      this.size = ''
      this.immediate = null
      this.monthlyDiskPrice = null
      this.newMonthlyServerPrice = null
    },

    priceString(price) {
      price = parseFloat(price)
      price = price >= 0 ? price : 0

      return Dinero({ amount: price, currency: 'EUR' })
        .setLocale('de-DE')
        .toFormat('0,0.00')
    },

    submit() {
      this.isLoading = true
      this.errors = {}
      const data = {
        serverId: this.$route.params.id,
        form: {
          name: this.diskName,
          size: this.size,
          auto_format: this.autoFormat
        }
      }

      this.$store.dispatch('createAdditionalDisk', data).finally(() => {
        this.isLoading = false
      })
    },

    isNumber: function (event) {
      event = (event) ? event : window.event

      const charCode = (event.which) ? event.which : event.keyCode

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        event.preventDefault()
      } else {
        return true
      }
    },

    calculateSize() {
      this.isPriceLoading = true
      const data = {
        serverId: this.$route.params.id,
        form: {
          size: this.size,
          auto_format: this.autoFormat
        }
      }

      this.$store.dispatch('calculateDiskPrice', data).then(() => {
        this.isPriceLoading = false
      })
    },
  },

  watch: {
    server: {
      handler(newServer) {
        // check if the server is not an empty object
        // prevent the function call every 5 seconds after the server is initialized
        if (newServer.uuid && !this.serverInitialized) {
          this.initializeAutoFormat();
          this.serverInitialized = true;
        }
      }
    },

    size: debounce(function (size) {
      this.size = size

      this.calculateSize()
    }, 750),

    diskPricing(pricing) {
      if (pricing) {
        this.errors = {}
        this.monthlyDiskPrice = this.priceString(pricing.monthly_payment)
        this.immediate = this.priceString(pricing.immediate_payment)
        this.newMonthlyServerPrice = this.priceString(pricing.monthly_payment + this.server.flavor.monthly_gross_price)
      }
    },

    diskPricingError(formErrors) {
      if (formErrors.errors) {
        const { errors, message } = formErrors
        this.errors = errors

        this.$toast.error(message)
      }
    },

    isDiskCreated(status) {
      if (status) {
        this.$emit('success')
      }
    },

    diskCreateErrors(formErrors) {
      if (formErrors.errors) {
        const { errors, message } = formErrors
        this.isLoading = false
        this.errors = errors

        this.$toast.error(message)
      } else if (formErrors.message) {
        this.isLoading = false
      }
    }
  }
}
</script>
